
function ListServiceEmployee({ array, onClickItem }) {

    function onClick(item) {
        onClickItem(item);
    }

    return (
        <div className="list-clients">
            {array.map((item) => {

                const login = item.login;
                let name = item.name;
                let phoneNumber = '';
                if (item.phoneNumber) {
                    phoneNumber = item.phoneNumber;
                }
                let role = '';
                if (item.role === 'serviceAdmin') {
                    role = 'админ';
                } else {
                    role = 'читатель';
                }
                const email = item.email;


                return (
                    <div key={item._id} className="item-list-clients" onClick={() => onClick(item)}>
                        <div className="item-list-clients__container">
                            <p className="item-list-clients__text">логин: {login}</p>
                            <p className="item-list-clients__text">имя: {name}</p>
                        </div>
                        {name || phoneNumber ?
                            <div className="item-list-clients__container">
                                <p className="item-list-clients__text">телефон: {phoneNumber}</p>
                                <p className="item-list-clients__text">email: {email}</p>
                            </div>
                            : <></>}
                        <div className="item-list-clients__container">
                            <p className="item-list-clients__text">роль: {role}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default ListServiceEmployee;