import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../context/AppContext';

import ButtonNavigationBack from "../../components/ButtonNavigationBack/ButtonNavigationBack";
import ButtonWithBorder from "../../components/ButtonWithBorder/ButtonWithBorder";
import InfoTooltip from "../../components/InfoTooltip/InfoTooltip";

import userApi from "../../utils/api/userApi";


function AccountPage({ }) {

    const [isLoadingLogout, setIsLoadingLogout] = useState(false);
    const [isOpenInfoTooltip, setIsOpenInfoTooltip] = useState(false);
    const [textInfotooltip, setTextInfotooltip] = useState('');

    const navigate = useNavigate();
    const { dictionary, idDevice, dataUser} = useAppContext();

    function onClickBackPage(e) {
        e.preventDefault();
        navigate('/');
    }


    function logout() {
        userApi.logout(idDevice)
            .then((data) => { navigate('/login') })
            .catch((error) => {
                console.log(error);
                setIsOpenInfoTooltip(true);
                setTextInfotooltip(dictionary.errorMessage)
            });
    }

    function closeOpenInfoTooltip() {
        setIsOpenInfoTooltip(true);
        setTextInfotooltip('');
    }

    return (
        <section className="account">
            <ButtonNavigationBack onClick={onClickBackPage} />
            <h2 className="account__title">Аккаунт</h2>
            <p className="account__text">{dictionary.textName}: {dataUser.name}</p>
            <p className="account__text">{dictionary.textEmail}: {dataUser.email}</p>
            <div className="account__button-logout">
                <ButtonWithBorder text={dictionary.buttonLogout} onClick={logout} isLoading={isLoadingLogout} />
            </div>
            <InfoTooltip isOpen={isOpenInfoTooltip} closeInfoTool={closeOpenInfoTooltip} text={textInfotooltip} />
        </section>
    )
}

export default AccountPage;