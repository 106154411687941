import { useEffect, useState } from "react";

import InputText from "../../../../components/InputText/InputText";
import ButtonWithBackgroundColor from "../../../../components/ButtonWithBackgroundColor/ButtonWithBackgroundColor";
import Loader from "../../../../components/Loader/Loader";
import InfoTooltip from "../../../../components/InfoTooltip/InfoTooltip";

import { useAppContext } from '../../../../context/AppContext';

import { regexStrokeInput, regexEmail, regexNumericInput } from "../../../../utils/regex";
import serviceApi from "../../../../utils/api/serviceApi";


function FormAddRecord() {

    const [recordName, setRecordName] = useState('');
    const [recordNamevalidationText, setRecordNamevalidationText] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [organizationNameValidationText, setOrganizationNameValidationText] = useState('');
    const [TIN, setRecordTIN] = useState('');
    const [TINValidationText, setTINValidationText] = useState('');
    const [email, setEmail] = useState('');
    const [emailValidationText, setEmailValidationText] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneValidationText, setPhoneValidationText] = useState('');
    const [numberMessanger, setNumberMessanger] = useState('');
    const [numberMessangerValidationText, setNumberMessangerValidationText] = useState('');
    const [country, setCountry] = useState('');
    const [countryValidationText, setCountryValidationText] = useState('');
    const [city, setCity] = useState('');
    const [cityValidationText, setCityValidationText] = useState('');
    const [street, setStreet] = useState('');
    const [streetValidationText, setStreetValidationText] = useState('');
    const [building, setBuilding] = useState('');
    const [buildingValidationText, setBuildingValidationText] = useState('');
    const [longitude, setLongitude] = useState('');
    const [longitudeValidationText, setLongitudeValidationText] = useState('');
    const [latitude, setLatitude] = useState('');
    const [latitudeValidationText, setLatitudeValidationText] = useState('');

    const [isValidForm, setIsValidForm] = useState(false);
    const [messageValidForm, setMessageValidForm] = useState('');
    // Формы

    // Инфотул
    const [isOpenInfoTooltip, setIsOpenInfoTooltip] = useState(false);
    const [textInfoTooltip, setTextInfoTooltip] = useState('');
    // Лоадер
    const [isLoading, setIsLoading] = useState(false);

    const { dictionary,  idDevice } = useAppContext();

    function submit() {
        if (isValidForm) {
            const service = {
                recordName,
                organizationName,
                TIN,
                email,
                phone,
                numberMessanger,
                country,
                city,
                street,
                building,
                longitude,
                latitude,
            }
            setIsLoading(true);
            serviceApi.createService(service, idDevice)
            .then((data)=>{
                console.log(data);
                openInfoTool(dictionary.succesfull)
                setIsLoading(false);
            })
            .catch((err)=>{
                openInfoTool(err.message)
                setIsLoading(false);
            })


        }
    
    }

    function openInfoTool(text) {
        setIsOpenInfoTooltip(true);
        setTextInfoTooltip(text);
    }

    function closeInfoTool() {
        setIsOpenInfoTooltip(false);
        setTextInfoTooltip('');
    }


    // Валидация
    useEffect(() => {

        const stateAndValidationPairs = [
            { stateName: recordName, validationSetState: setRecordNamevalidationText, regex: regexStrokeInput, valid: false },
            { stateName: organizationName, validationSetState: setOrganizationNameValidationText, regex: regexStrokeInput, valid: false },
            { stateName: TIN, validationSetState: setTINValidationText, regex: regexNumericInput, valid: false },
            { stateName: email, validationSetState: setEmailValidationText, regex: regexEmail, valid: false },
            { stateName: phone, validationSetState: setPhoneValidationText, regex: regexNumericInput, valid: false },
            { stateName: numberMessanger, validationSetState: setNumberMessangerValidationText, regex: regexNumericInput, valid: false },
            { stateName: country, validationSetState: setCountryValidationText, regex: regexStrokeInput, valid: false },
            { stateName: city, validationSetState: setCityValidationText, regex: regexStrokeInput, valid: false },
            { stateName: street, validationSetState: setStreetValidationText, regex: regexStrokeInput, valid: false },
            { stateName: building, validationSetState: setBuildingValidationText, regex: regexStrokeInput, valid: false },
            { stateName: longitude, validationSetState: setLongitudeValidationText, regex: regexNumericInput, valid: false },
            { stateName: latitude, validationSetState: setLatitudeValidationText, regex: regexNumericInput, valid: false },
        ];

        stateAndValidationPairs.forEach((item) => {
            if (item.stateName !== '') {
                const regex = item.regex;
                if (regex.test(item.stateName)) {
                    item.validationSetState('');
                    item.valid = true;
                } else {
                    item.validationSetState(dictionary.validationRegex)
                    item.valid = false;
                }
            } else {
                item.validationSetState(dictionary.validationValue);
                item.valid = false;
            }
        });

        const validForm = stateAndValidationPairs.find((item) => item.valid === false);
        setIsValidForm(!validForm);




    }, [recordName, organizationName, TIN, email, phone, numberMessanger, country, city, street, building, longitude, latitude])


    return (
        <div className="form-add-record">
            <p className="form-add-record__title">Новый сервис</p>
            <div className="form-add-record__container-input">
                <InputText
                    inputValue={recordName}
                    setInputChange={setRecordName}
                    placeholder={'Название сервиса для клиентов'}
                    label={'Краткое название'}
                    validationText={recordNamevalidationText}
                />
                <InputText
                    inputValue={organizationName}
                    setInputChange={setOrganizationName}
                    placeholder={'Полное название сервиса'}
                    label={'Полное название'}
                    validationText={organizationNameValidationText}
                />
                <InputText
                    inputValue={TIN}
                    setInputChange={setRecordTIN}
                    placeholder={'ИНН или другой идентификатор'}
                    label={'ИНН'}
                    validationText={TINValidationText}
                />
                <InputText
                    inputValue={email}
                    setInputChange={setEmail}
                    placeholder={'Email'}
                    label={'Email'}
                    validationText={emailValidationText}
                />
                <InputText
                    inputValue={numberMessanger}
                    setInputChange={setNumberMessanger}
                    placeholder={'Номер для мессенджера'}
                    label={'Мессенджер'}
                    validationText={numberMessangerValidationText}
                />
                <InputText
                    inputValue={phone}
                    setInputChange={setPhone}
                    placeholder={'Телефон для связи'}
                    label={'Телефон'}
                    validationText={phoneValidationText}
                />
                <InputText
                    inputValue={country}
                    setInputChange={setCountry}
                    placeholder={'Страна'}
                    label={'Страна'}
                    validationText={countryValidationText}
                />
                <InputText
                    inputValue={city}
                    setInputChange={setCity}
                    placeholder={'Город'}
                    label={'Город'}
                    validationText={cityValidationText}
                />
                <InputText
                    inputValue={street}
                    setInputChange={setStreet}
                    placeholder={'Улица'}
                    label={'Улица'}
                    validationText={streetValidationText}
                />
                <InputText
                    inputValue={building}
                    setInputChange={setBuilding}
                    placeholder={'Дом'}
                    label={'Дом'}
                    validationText={buildingValidationText}
                />
                <InputText
                    inputValue={longitude}
                    setInputChange={setLongitude}
                    placeholder={'Долгота'}
                    label={'Долгота'}
                    validationText={longitudeValidationText}
                />
                <InputText
                    inputValue={latitude}
                    setInputChange={setLatitude}
                    placeholder={'Широта'}
                    label={'Широта'}
                    validationText={latitudeValidationText}
                />

            </div>
            <ButtonWithBackgroundColor text={'Новый сервис'} isLoading={isLoading} isDisabled={!isValidForm} onClick={submit} />
            <p className="form-add-work__error-message">{messageValidForm}</p>
            <InfoTooltip isOpen={isOpenInfoTooltip} closeInfoTool={closeInfoTool} text={textInfoTooltip} />
        </div>
    )
}

export default FormAddRecord;