import { useEffect, useState } from "react";
import { useAppContext } from '../../context/AppContext';
import InputText from "../InputText/InputText";
import ButtonWithBackgroundColor from "../ButtonWithBackgroundColor/ButtonWithBackgroundColor";
import { regexEmail, regexPassword } from "../../utils/regex";

function Login({ onLogin, isLoading }) {

    const { dictionary } = useAppContext();

    // Стейты, в которых содержятся значения инпута
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [validationMassegePassword, setValidationMassegePassword] = useState(dictionary.validationValue);
    const [validationMassegeLogin, setValidationMassegeLogin] = useState(dictionary.validationValue);
    const [validationAll, setValidationAll] = useState(false);

    function submit() {
        const data = {
            login: login,
            password: password,
        }
        onLogin(data);
    }

    // Валидация
    useEffect(() => {
        let validLogin = false;
        let validPassword = false;

        if (login !== '') {
            if (regexEmail.test(login)) {
                validLogin = true;
                setValidationMassegeLogin('');
            } else {
                validLogin = false;
                setValidationMassegeLogin(dictionary.validationRegex);
            }
        } else {
            validLogin = false;
            setValidationMassegeLogin(dictionary.validationValue);
        }

        if (password !== '') {
            if (regexPassword.test(password)) {
                validPassword = true;
                setValidationMassegePassword('');
            } else {
                validPassword = false;
                setValidationMassegePassword(dictionary.validationRegex);
            }
        } else {
            validPassword = false;
            setValidationMassegePassword(dictionary.validationValue);
        }

        if (validLogin && validPassword) {
            setValidationAll(true);
        } else {
            setValidationAll(false);
        }

    }, [login, password])


    return (
        <div className="login">
            <h2 className="login__title">{dictionary.titleLogin}</h2>
            <InputText
                inputValue={login}
                setInputChange={setLogin}
                placeholder={dictionary.placeholderLogin}
                validationText={validationMassegeLogin}
                label={dictionary.placeholderLogin}
            />
            <InputText
                inputValue={password}
                setInputChange={setPassword}
                placeholder={dictionary.placeholderPassword}
                validationText={validationMassegePassword}
                label={dictionary.placeholderPassword}
            />
            <div className="login__button">
                <ButtonWithBackgroundColor isLoading={isLoading} text={dictionary.buttonLogin} onClick={submit} isDisabled={!validationAll} />
            </div>
            <div className="login__container-text-password-recover">
                <p className="login__container-text-password-recover-text">{dictionary.textQuestionRecoveryPassword}</p>
                <button className="login__container-text-password-recover-link">{dictionary.textRecovery}</button>
            </div>
        </div>
    )
}

export default Login;