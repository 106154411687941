import React, { createContext, useState, useContext, useEffect } from 'react';
import { ru as RuTranslate, en as EnTranslate, countriesCIS } from '../utils/translate';
import { getOrGenerateUniqueCode } from '../utils/localStorageUtils';
import userApi from '../utils/api/userApi';

const AppContext = createContext();

export function AppProvider({ children }) {
    const [idUser, setIdUser] = useState('');
    const [dataUser, setDataUser] = useState({});
    const [dictionary, setDictionary] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [idDevice, setIdDevice] = useState('idDevice');
    const [isLoadingAuthenticated, setIsLoadingAuthenticated] = useState(true);

    const userLocale = navigator.language;

    // Получить локаль и перевод
    useEffect(() => {
        console.log(userLocale)
        if (countriesCIS.includes(userLocale)) {
            setDictionary(RuTranslate)
        } else {
            setDictionary(EnTranslate)
        }
    }, [userLocale])

    // Получить уникальный код для браузера
    useEffect(() => {
        getOrGenerateUniqueCode()
            .then((uniqueCode) => setIdDevice(uniqueCode))
            .catch((error) => console.log(error))
    }, [])

    // Проверить авторизацию пользователя и наполнить данные
    useEffect(() => {
        if (idDevice !== 'idDevice') {
            setIsLoadingAuthenticated(true);
            userApi.getUserInfo(idDevice)
                .then((data) => {
                    setIdUser(data.user._id);
                    setDataUser(data.user);
                    setIsAuthenticated(true);
                    setIsLoadingAuthenticated(false);
                }).catch((err) => {
                    setIsLoadingAuthenticated(false);
                    setIsAuthenticated(false);
                })
        }
    }, [idDevice])

    const login = (data) => {
        setIsAuthenticated(data.authState);
        if (data.authState) {
            setIdUser(data.user._id);
            setDataUser(data.user);
        }
    };

    const logout = () => {
        setIsAuthenticated(false);
    };

    return (
        <AppContext.Provider value={{
            isAuthenticated,
            isLoadingAuthenticated,
            login,
            logout,
            dictionary,
            idUser,
            idDevice,
            dataUser,
        }}>
            {children}
        </AppContext.Provider>
    );
}

export function useAppContext() {
    return useContext(AppContext);
}
