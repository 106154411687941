import { useNavigate } from "react-router-dom";

import FormAddRecord from "./components/FormAddRecord/FormAddRecord";
import ButtonNavigationBack from "../../components/ButtonNavigationBack/ButtonNavigationBack";
import InfoTooltip from "../../components/InfoTooltip/InfoTooltip";

function NewServicePage({ }) {

    const navigate = useNavigate();

    function onClickBackPage(e) {
        e.preventDefault();
        navigate('/');
    }

    return (
        <section className="new-maintenance-page">
            <ButtonNavigationBack onClick={onClickBackPage} />
            <FormAddRecord />
        </section>
    )
}

export default NewServicePage;