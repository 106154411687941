import { useEffect, useState } from "react";
import { useAppContext } from '../../../../context/AppContext';

import InfoTooltip from "../../../../components/InfoTooltip/InfoTooltip";
import ButtonWithBorder from "../../../../components/ButtonWithBorder/ButtonWithBorder";
import ButtonWithImg from "../../../../components/ButtonWithImg/ButtonWithImg";
import InputText from "../../../../components/InputText/InputText";

import { regexStrokeInput } from "../../../../utils/regex";
import imgUpdate from "../../../../images/edit-2-svgrepo-com.svg";
import imgRemove from "../../../../images/trash-alt-svgrepo-com.svg";

function FormSelectCustomerRecord({ isOpen, closeForm, text, removeItem, updateItem, selectedItem, isLoadingUpdate, isLoadingRemove }) {

    const { dictionary } = useAppContext();
    const [inputValue, setInputValue] = useState('');
    const [validationText, setValidationText] = useState(false);
    const [validation, setValidation] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isRemove, setIsRemove] = useState(false);

    function openUpdate() {
        setIsUpdate(true);
    }

    function openRemove() {
        setIsRemove(true);
    }

    function update() {
        const text = inputValue.lastIndexOf(','); // Находим индекс последней запятой
        const trimmedText = inputValue.substring(0, text); // Выбираем текст до последней запятой
        updateItem(trimmedText);
    }

    useEffect(() => {
        if (selectedItem) {
            const text = selectedItem.title.lastIndexOf(','); // Находим индекс последней запятой
            const trimmedText = selectedItem.title.substring(0, text); // Выбираем текст до последней запятой
            console.log(selectedItem.title.lastIndexOf(','))
            setInputValue(trimmedText)
        }
        setIsUpdate(false);
        setIsRemove(false);
    }, [isOpen])


    useEffect(() => {

        if (inputValue === selectedItem.title) {
            setValidation(false);
        } else {
            if (inputValue.length > 0) {

                if (regexStrokeInput.test(inputValue)) {
                    setValidation(true);
                    setValidationText('');
                } else {
                    setValidation(false);
                    setValidationText(dictionary.validationRegex)
                }

            } else {
                setValidation(false);
                setValidationText(dictionary.validationValue)
            }
        }
    }, [inputValue, selectedItem])


    return (
        <InfoTooltip
            isOpen={isOpen}
            closeInfoTool={closeForm}
            text={text}
            children={
                <div className="form-select-customer-record">
                    {isUpdate ?
                        <>
                            <InputText inputValue={inputValue} setInputChange={setInputValue} placeholder={dictionary.placeholderNameWork} validationText={validationText} label={dictionary.labelNameWork} />
                            <div className="form-select-customer-record__button">
                                <ButtonWithBorder text={dictionary.buttonUpdate} onClick={update} isDisabled={!validation} isLoading={isLoadingUpdate} />
                            </div>
                        </>
                        : <></>}
                    {isRemove ?
                        <div className="form-select-customer-record__button-container">
                            <div className="form-select-customer-record__button">
                                <ButtonWithBorder text={dictionary.textYes} onClick={removeItem} isLoading={isLoadingRemove} />
                            </div>
                            <div className="form-select-customer-record__button">
                                <ButtonWithBorder text={dictionary.textNo} onClick={closeForm} />
                            </div>
                        </div>
                        : <></>}
                    {!isUpdate && !isRemove ?
                        <div className="form-select-customer-record__button-container form-select-customer-record__button-container_update-or-remove">
                            <div className="form-select-customer-record__button">
                                <ButtonWithImg img={imgUpdate} onClick={openUpdate} />
                            </div>
                            <div className="form-select-customer-record__button">
                                <ButtonWithImg img={imgRemove} onClick={openRemove} />
                            </div>
                        </div>
                        :
                        <></>
                    }
                </div>
            }
        />
    )
}

export default FormSelectCustomerRecord;