import host from "./host";

class Api {
    constructor(data) {
        this.host = data.host;
    }

    // проверка статуса запроса
    _getResponse(res) {
        if (res.ok) {
            return res.json();
        } else {
            return res.json().then((errorJson) => {
                const error = new Error(errorJson.message);
                error.json = errorJson;
                error.status = res.status;
                return Promise.reject(error);
            });
        }
    }

    createService(data, idDevice) {
        return fetch(`${this.host}/services-create/${idDevice}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: data.recordName,
                organizationName: data.organizationName,
                TIN: data.TIN,
                email: data.email,
                phone: data.phone,
                numberMessanger: data.numberMessanger,
                country: data.country,
                city: data.city,
                street: data.street,
                building: data.building,
                longitude: data.longitude,
                latitude: data.latitude,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    updateService(data, idDevice) {
        return fetch(`${this.host}/services-update/${idDevice}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idService: data.idService,
                name: data.recordName,
                organizationName: data.organizationName,
                TIN: data.TIN,
                email: data.email,
                phone: data.phone,
                numberMessanger: data.numberMessanger,
                country: data.country,
                city: data.city,
                street: data.street,
                building: data.building,
                longitude: data.longitude,
                latitude: data.latitude,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    removeService(idService, idDevice) {
        return fetch(`${this.host}/services-delete/${idDevice}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idService: idService,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    getServiceBySearch(search, idDevice) {
        return fetch(`${this.host}/get-service-by-search/${search}/${idDevice}`,
            {
                method: 'GET',
                credentials: 'include',
            }).then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            })
    }

    // получить клиентов сервиса
    getClientsServiceByServiceEmployee(idUser, idDevice) {
        return fetch(`${this.host}/get-clients-service-by-service-employee/${idDevice}`, {
            method: 'GET',
            credentials: 'include',
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    updateClientsServiceByServiceEmployee(idUser, idDevice, data) {
        return fetch(`${this.host}/update-client-service-by-service-employee/${idDevice}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idClient: data.idClient,
                nameClient: data.name,
                phoneNumberClient: data.phoneNumber,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    getMaintenancesByServiceEmployee(idUser, idDevice) {
        return fetch(`${this.host}/get-maintenances-by-service-employee/${idDevice}`,
            {
                method: 'GET',
                credentials: 'include', // добавляем заголовок Authorization с токеном
            },
        ).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    getMaintenancesByServiceEmployeeByDateRange(dateOn, dateTo, idDevice) {
        return fetch(`${this.host}/get-maintenances-by-service-employee-by-date-range/${dateOn}/${dateTo}/${idDevice}`,
            {
                method: 'GET',
                credentials: 'include', // добавляем заголовок Authorization с токеном
            },
        ).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    getMaintenancesByServiceEmployeeByVINOrClientLogin(vinOrLogin, idDevice) {
        return fetch(`${this.host}/get-maintenances-by-service-employee-by-vin-login/${vinOrLogin}/${idDevice}`,
            {
                method: 'GET',
                credentials: 'include', // добавляем заголовок Authorization с токеном
            },
        ).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    createServiceEmployee(data, idDevice) {
        return fetch(`${this.host}/create-service-employee/${idDevice}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: data.name,
                role: data.role,
                email: data.email,
                service: data.service,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    updateServiceEmployee(data, idDevice) {
        return fetch(`${this.host}/update-service-employee/${idDevice}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idEmployee: data.idEmployee,
                name: data.name,
                role: data.role,
                email: data.email,
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    recoveryPasswordServiceEmployee(data, idDevice) {
        return fetch(`${this.host}/recovery-password-service-employee/${idDevice}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idEmployee: data.idEmployee,
                email: data.email
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

    deletePasswordServiceEmployee(idEmployee, idDevice) {
        return fetch(`${this.host}/delete-service-employee/${idDevice}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idEmployee: idEmployee
            })
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    }

}

const serviceApi = new Api({
    host: host,
});

export default serviceApi;