import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import InputText from "../../../../components/InputText/InputText";
import ButtonWithBackgroundColor from "../../../../components/ButtonWithBackgroundColor/ButtonWithBackgroundColor";
import Loader from "../../../../components/Loader/Loader";
import InfoTooltip from "../../../../components/InfoTooltip/InfoTooltip";

import { useAppContext } from '../../../../context/AppContext';

import { regexStrokeInput, regexEmail, regexNumericInput } from "../../../../utils/regex";
import serviceApi from "../../../../utils/api/serviceApi";
import FormRemove from "../../../../components/FormRemove/FormRemove";


function FormUpdateService({ item }) {

    const [recordName, setRecordName] = useState('');
    const [recordNamevalidationText, setRecordNamevalidationText] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [organizationNameValidationText, setOrganizationNameValidationText] = useState('');
    const [TIN, setRecordTIN] = useState('');
    const [TINValidationText, setTINValidationText] = useState('');
    const [email, setEmail] = useState('');
    const [emailValidationText, setEmailValidationText] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneValidationText, setPhoneValidationText] = useState('');
    const [numberMessanger, setNumberMessanger] = useState('');
    const [numberMessangerValidationText, setNumberMessangerValidationText] = useState('');
    const [country, setCountry] = useState('');
    const [countryValidationText, setCountryValidationText] = useState('');
    const [city, setCity] = useState('');
    const [cityValidationText, setCityValidationText] = useState('');
    const [street, setStreet] = useState('');
    const [streetValidationText, setStreetValidationText] = useState('');
    const [building, setBuilding] = useState('');
    const [buildingValidationText, setBuildingValidationText] = useState('');
    const [longitude, setLongitude] = useState('');
    const [longitudeValidationText, setLongitudeValidationText] = useState('');
    const [latitude, setLatitude] = useState('');
    const [latitudeValidationText, setLatitudeValidationText] = useState('');

    const [isValidForm, setIsValidForm] = useState(false);
    const [messageValidForm, setMessageValidForm] = useState('');
    // Формы
    const [isOpenFormRemove, setIsOpenFormRemove] = useState(false);
    // Инфотул
    const [isOpenInfoTooltip, setIsOpenInfoTooltip] = useState(false);
    const [textInfoTooltip, setTextInfoTooltip] = useState('');
    // Лоадер
    const [isLoadingUpdate, setIsLoading] = useState(false);
    const [isLoadingRemove, setIsLoadingRemove] = useState(false);

    const { dictionary, idDevice } = useAppContext();
    const navigate = useNavigate();

    function submit() {
        if (isValidForm) {
            const states = [
                { state: recordName, startState: item.name, name: 'name' },
                { state: organizationName, startState: item.organizationName, name: 'organizationName' },
                { state: TIN, startState: item.TIN, name: 'TIN' },
                { state: email, startState: item.email, name: 'email' },
                { state: phone, startState: item.phone, name: 'phone' },
                { state: numberMessanger, startState: item.numberMessanger, name: 'numberMessanger' },
                { state: country, startState: item.address.country, name: 'country' },
                { state: city, startState: item.address.city, name: 'city' },
                { state: street, startState: item.address.street, name: 'street' },
                { state: building, startState: item.address.building, name: 'building' },
                { state: longitude, startState: item.address.longitude, name: 'longitude' },
                { state: latitude, startState: item.address.latitude, name: 'latitude' }
            ]

            const service = {
                idService: item._id,
            }

            states.forEach(item => {
                if (item.state !== item.startState) {
                    const key = item.name;
                    service[key] = item.state;
                }
            })

            setIsLoading(true);
            serviceApi.updateService(service, idDevice)
                .then((data) => {
                    console.log(data);
                    openInfoTool(dictionary.succesfull)
                    setIsLoading(false);
                })
                .catch((err) => {
                    openInfoTool(err.message)
                    setIsLoading(false);
                })

        }

    }

    function remove() {
        const idService = item._id;

        serviceApi.removeService(idService, idDevice)
            .then((data) => {
                openInfoTool('Сервис удален');
                navigate('/');
            })
            .catch((err) => {
                openInfoTool(err.message);
            });
    }

    function openInfoTool(text) {
        setIsOpenInfoTooltip(true);
        setTextInfoTooltip(text);
    }

    function closeInfoTool() {
        setIsOpenInfoTooltip(false);
        setTextInfoTooltip('');
    }

    function openFormRemove() {
        setIsOpenFormRemove(true);
    }

    function closeFormRemove() {
        setIsOpenFormRemove(false);
    }

    // Наполнить форму
    useEffect(() => {
        if (item) {
            setRecordName(item.name);
            setOrganizationName(item.organizationName);
            setRecordTIN(item.TIN);
            setEmail(item.email);
            setPhone(item.phone);
            setNumberMessanger(item.numberMessanger);
            setCountry(item.address.country);
            setCity(item.address.city);
            setStreet(item.address.street);
            setBuilding(item.address.building);
            setLongitude(item.address.longitude);
            setLatitude(item.address.latitude);
        }

    }, [item])


    // Валидация
    useEffect(() => {

        const stateAndValidationPairs = [
            { stateName: recordName, validationSetState: setRecordNamevalidationText, regex: regexStrokeInput, valid: false, startState: item.name },
            { stateName: organizationName, validationSetState: setOrganizationNameValidationText, regex: regexStrokeInput, valid: false, startState: item.organizationName },
            { stateName: TIN, validationSetState: setTINValidationText, regex: regexNumericInput, valid: false, startState: item.TIN },
            { stateName: email, validationSetState: setEmailValidationText, regex: regexEmail, valid: false, startState: item.email },
            { stateName: phone, validationSetState: setPhoneValidationText, regex: regexNumericInput, valid: false, startState: item.phone },
            { stateName: numberMessanger, validationSetState: setNumberMessangerValidationText, regex: regexNumericInput, valid: false, startState: item.numberMessanger },
            { stateName: country, validationSetState: setCountryValidationText, regex: regexStrokeInput, valid: false, startState: item.address.country },
            { stateName: city, validationSetState: setCityValidationText, regex: regexStrokeInput, valid: false, startState: item.address.city },
            { stateName: street, validationSetState: setStreetValidationText, regex: regexStrokeInput, valid: false, startState: item.address.street },
            { stateName: building, validationSetState: setBuildingValidationText, regex: regexStrokeInput, valid: false, startState: item.address.building },
            { stateName: longitude, validationSetState: setLongitudeValidationText, regex: regexNumericInput, valid: false, startState: item.address.longitude },
            { stateName: latitude, validationSetState: setLatitudeValidationText, regex: regexNumericInput, valid: false, startState: item.address.latitude },
        ];

        stateAndValidationPairs.forEach((item) => {
            if (item.stateName !== '') {
                const regex = item.regex;
                if (regex.test(item.stateName)) {
                    item.validationSetState('');
                    item.valid = true;
                } else {
                    item.validationSetState(dictionary.validationRegex)
                    item.valid = false;
                }
            } else {
                item.validationSetState(dictionary.validationValue);
                item.valid = false;
            }
        });

        const validForm = stateAndValidationPairs.find((state) => state.valid === false);
        const isChange = stateAndValidationPairs.find((state) => state.stateName !== state.startState);
        if (!validForm && isChange) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }




    }, [recordName, organizationName, TIN, email, phone, numberMessanger, country, city, street, building, longitude, latitude])


    return (
        <div className="form-add-record">
            <p className="form-add-record__title">Редактировать</p>
            <div className="form-add-record__container-input">
                <InputText
                    inputValue={recordName}
                    setInputChange={setRecordName}
                    placeholder={'Название сервиса для клиентов'}
                    label={'Краткое название'}
                    validationText={recordNamevalidationText}
                />
                <InputText
                    inputValue={organizationName}
                    setInputChange={setOrganizationName}
                    placeholder={'Полное название сервиса'}
                    label={'Полное название'}
                    validationText={organizationNameValidationText}
                />
                <InputText
                    inputValue={TIN}
                    setInputChange={setRecordTIN}
                    placeholder={'ИНН или другой идентификатор'}
                    label={'ИНН'}
                    validationText={TINValidationText}
                />
                <InputText
                    inputValue={email}
                    setInputChange={setEmail}
                    placeholder={'Email'}
                    label={'Email'}
                    validationText={emailValidationText}
                />
                <InputText
                    inputValue={numberMessanger}
                    setInputChange={setNumberMessanger}
                    placeholder={'Номер для мессенджера'}
                    label={'Мессенджер'}
                    validationText={numberMessangerValidationText}
                />
                <InputText
                    inputValue={phone}
                    setInputChange={setPhone}
                    placeholder={'Телефон для связи'}
                    label={'Телефон'}
                    validationText={phoneValidationText}
                />
                <InputText
                    inputValue={country}
                    setInputChange={setCountry}
                    placeholder={'Страна'}
                    label={'Страна'}
                    validationText={countryValidationText}
                />
                <InputText
                    inputValue={city}
                    setInputChange={setCity}
                    placeholder={'Город'}
                    label={'Город'}
                    validationText={cityValidationText}
                />
                <InputText
                    inputValue={street}
                    setInputChange={setStreet}
                    placeholder={'Улица'}
                    label={'Улица'}
                    validationText={streetValidationText}
                />
                <InputText
                    inputValue={building}
                    setInputChange={setBuilding}
                    placeholder={'Дом'}
                    label={'Дом'}
                    validationText={buildingValidationText}
                />
                <InputText
                    inputValue={longitude}
                    setInputChange={setLongitude}
                    placeholder={'Долгота'}
                    label={'Долгота'}
                    validationText={longitudeValidationText}
                />
                <InputText
                    inputValue={latitude}
                    setInputChange={setLatitude}
                    placeholder={'Широта'}
                    label={'Широта'}
                    validationText={latitudeValidationText}
                />

            </div>
            <div className="form-update-service__buttons">
                <ButtonWithBackgroundColor text={'Удалить'} onClick={openFormRemove} />
                <ButtonWithBackgroundColor text={'Редактировать'} isLoading={isLoadingUpdate} isDisabled={!isValidForm} onClick={submit} />
            </div>
            <p className="form-add-work__error-message">{messageValidForm}</p>
            <FormRemove isOpen={isOpenFormRemove} closeForm={closeFormRemove} onRemove={remove} isLoading={isLoadingRemove} />
            <InfoTooltip isOpen={isOpenInfoTooltip} closeInfoTool={closeInfoTool} text={textInfoTooltip} />
        </div>
    )
}

export default FormUpdateService;