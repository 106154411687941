import { useEffect, useState } from "react";
import InfoTooltip from "../../../../components/InfoTooltip/InfoTooltip";

import { regexEmail, regexStrokeInput } from "../../../../utils/regex";
import InputText from "../../../../components/InputText/InputText";
import InputSelect from "../../../../components/InputSelect/InputSelect";
import ButtonWithBorder from "../../../../components/ButtonWithBorder/ButtonWithBorder";

function FormUpdateServiceEmployee({ item, isOpen, closeForm, onSubmit, isLoading, submitRecoveryPassword, isLoadingRecoveryPassword }) {

    const [name, setName] = useState('');
    const [nameValidText, setNameValidText] = useState('');
    const [email, setEmail] = useState('');
    const [emailValidText, setEmailValidText] = useState('');
    const [role, setRole] = useState({
        value: 'serviceAdmin', label: 'админ'
    });
    const [roleValidText, setRoleValidText] = useState('');
    const [isValid, setIsValid] = useState(false);

    const optionRole = [
        {
            value: 'serviceAdmin', label: 'админ'
        },
        {
            value: 'serviceReader', label: 'только чтение и запись на ТО'
        },

    ]

    function submit() {

        let data = {
            idEmployee: item._id,
        }

        if (name !== item.name) {
            data.name = name;
        }
        if (email !== item.email) {
            data.name = email;
        }
        if (role.value !== item.role) {
            data.role = role.value;
        }

        onSubmit(data)
    }

    // Наполнить инпуты
    useEffect(() => {

        const name = item.name;
        const email = item.email;
        const role = optionRole.find((option) => option.value === item.role);

        setName(name);
        setEmail(email);
        setRole(role);

    }, [])

    // Валидация
    useEffect(() => {

        if (name !== item.name || email !== item.email || role.value !== item.role) {
            const optionsValid = [
                { state: name, setValidText: setNameValidText, regex: regexStrokeInput, isValid: false },
                { state: email, setValidText: setEmailValidText, regex: regexEmail, isValid: false },
                { state: role.value, setValidText: setRoleValidText, regex: regexStrokeInput, isValid: false },
            ]

            optionsValid.forEach(option => {
                if (option.state !== '') {
                    if (option.regex.test(option.state)) {
                        option.isValid = true;
                        option.setValidText('')
                    } else {
                        option.isValid = false;
                        option.setValidText('Не корректный ввод')
                    }
                } else {
                    option.isValid = false;
                    option.setValidText('Пропущено поле')
                }
            })
            const valid = optionsValid.find(option => option.isValid === false);
            setIsValid(!valid);

        } else {
            setIsValid(false);
        }

    }, [name, email, role, item])

    return (
        <InfoTooltip
            isOpen={isOpen}
            closeInfoTool={closeForm}
            text={'Редактировать'}
            children={<div className="form-add-service-employee">
                <InputText inputValue={name} setInputChange={setName} placeholder={'ФИО'} validationText={nameValidText} label={'ФИО'} />
                <InputText inputValue={email} setInputChange={setEmail} placeholder={'Email'} validationText={emailValidText} label={'Email'} />
                <InputSelect options={optionRole} setSelectValue={setRole} value={role} placeholder={'Роль'} validationText={roleValidText} handleInputChange={() => { }} />
                <div className="form-add-service-employee__button">
                    <ButtonWithBorder text={'Редактировать'} onClick={submit} isLoading={isLoading} isDisabled={!isValid} />
                </div>
                <div className="form-add-service-employee__button">
                    <ButtonWithBorder text={'Обновить пароль'} onClick={submitRecoveryPassword} isLoading={isLoading} />
                </div>
            </div>}
            customStyle={''}
        />
    )

}

export default FormUpdateServiceEmployee;