import { useEffect, useState } from "react";

import InfoTooltip from "../../../../components/InfoTooltip/InfoTooltip";
import InputText from "../../../../components/InputText/InputText";
import InputSelect from "../../../../components/InputSelect/InputSelect";
import ButtonWithBorder from "../../../../components/ButtonWithBorder/ButtonWithBorder";

import { regexEmail, regexStrokeInput } from "../../../../utils/regex";

function FormAddServiceEmployees({ isOpen, closeForm, isLoading, onSubmit }) {

    const [name, setName] = useState('');
    const [nameValidText, setNameValidText] = useState('');
    const [email, setEmail] = useState('');
    const [emailValidText, setEmailValidText] = useState('');
    const [role, setRole] = useState({
        value: 'serviceAdmin', label: 'админ'
    });
    const [roleValidText, setRoleValidText] = useState('');
    const [isValid, setIsValid] = useState(false);

    const optionRole = [
        {
            value: 'serviceAdmin', label: 'админ'
        },
        {
            value: 'serviceReader', label: 'только чтение и запись на ТО'
        },

    ]

    function submit() {

        const data = {
            name,
            email,
            role: role.value,
        }

        onSubmit(data)
    }

    // Валидация
    useEffect(() => {

        const optionsValid = [
            { state: name, setValidText: setNameValidText, regex: regexStrokeInput, isValid: false },
            { state: email, setValidText: setEmailValidText, regex: regexEmail, isValid: false },
            { state: role.value, setValidText: setRoleValidText, regex: regexStrokeInput, isValid: false },
        ]

        optionsValid.forEach(option => {
            if (option.state !== '') {
                if (option.regex.test(option.state)) {
                    option.isValid = true;
                    option.setValidText('')
                } else {
                    option.isValid = false;
                    option.setValidText('Не корректный ввод')
                }
            } else {
                option.isValid = false;
                option.setValidText('Пропущено поле')
            }
        })

        const valid = optionsValid.find(option => option.isValid === false);
        setIsValid(!valid);

    }, [name, email, role])

    return (
        <InfoTooltip
            isOpen={isOpen}
            closeInfoTool={closeForm}
            text={'Добавить сотрудника'}
            children={
                <div className="form-add-service-employee">
                    <InputText inputValue={name} setInputChange={setName} placeholder={'ФИО'} validationText={nameValidText} label={'ФИО'} />
                    <InputText inputValue={email} setInputChange={setEmail} placeholder={'Email'} validationText={emailValidText} label={'Email'} />
                    <InputSelect options={optionRole} setSelectValue={setRole} value={role} placeholder={'Роль'} validationText={roleValidText} handleInputChange={() => { }} />
                    <div className="form-add-service-employee__button">
                        <ButtonWithBorder text={'Добавить'} onClick={submit} isLoading={isLoading} isDisabled={!isValid} />
                    </div>
                </div>
            }
            customStyle={''}
        />
    )
}

export default FormAddServiceEmployees;