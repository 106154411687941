

import { useEffect, useState } from "react";

import InputText from "../../../components/InputText/InputText";
import InputSelect from "../../../components/InputSelect/InputSelect";
import ButtonWithBackgroundColor from "../../../components/ButtonWithBackgroundColor/ButtonWithBackgroundColor";
import FormAddWork from "../../../Pages/NewServicePage/components/FormAddWork/FormAddWork";
import FormAddTransport from "../../../Pages/NewServicePage/components/FormAddRecord/components/FormAddTransport/FormAddTransport";
import FormCheckTransport from "../../../Pages/NewServicePage/components/FormAddRecord/components/FormCheckTransport/FormCheckTransport";
import FormUpdateTransport from "../../../Pages/NewServicePage/components/FormAddRecord/components/FormUpdateTransport/FormUpdateTransport";
import Loader from "../../../components/Loader/Loader";
import InfoTooltip from "../../../components/InfoTooltip/InfoTooltip";

import { useAppContext } from '../../../context/AppContext';

import { getTransportByVIN } from "./utils/transportUtils";
import {
    validationLoginAndVIN,
    validationRecordName,
    validationMillage,
    validationTypeOfWork,
    validationClientLogin,
    validationVIN,
    validationWorks,
    validationTransportForm
} from "./utils/validationUtils.js";
import { regexVIN } from "../../../utils/regex";
import { sendEmail, supportEmail } from "../../../utils/email/emailUtils";

import transportApi from "../../../utils/api/transportApi";
import maintenanceApi from "../../../utils/api/maintenanceApi";

function FormUpdateMaintenance({ isOpen, closeForm, onSubmit, text, item }) {

    const [recordName, setRecordName] = useState('');
    const [recordNamevalidationText, setRecordNamevalidationText] = useState('');
    const [millage, setMillage] = useState('');
    const [millageValidationText, setMillageValidationText] = useState('');
    const [typeOfWork, setTypeOfWork] = useState('');
    const [validationTypeOfWorkText, setValidationTypeOfWorkText] = useState('');
    const [clientLogin, setClientLogin] = useState('');
    const [validationClientLoginText, setValidationClientLoginText] = useState('');
    const [VIN, setVIN] = useState('');
    const [validationVINText, setValidationVINText] = useState('');
    const [cost, setCost] = useState('');
    const [works, setWorks] = useState([]);
    const [date, setDate] = useState(new Date());
    const [updateData, setUpdateData] = useState({});
    // Транспорт
    const [transport, setTransport] = useState({});
    // Формы
    const [isOpenTransportForm, setOpenTransportForm] = useState(false);
    const [isOpenUpdateTransportForm, setOpenUpdateTransportForm] = useState(false);
    const [isOpenCheckTransportForm, setIsOpenCheckTransportForm] = useState(false);
    const [isValidForm, setIsValidForm] = useState(false);
    const [messageValidForm, setMessageValidForm] = useState('');
    // Инфотул
    const [isOpenInfoTooltip, setIsOpenInfoTooltip] = useState(false);
    const [textInfoTooltip, setTextInfoTooltip] = useState('');
    // Лоадер
    const [isLoadingVIN, setIsLoadingVIN] = useState(false);
    const [isLoadingAddTransport, setIsLoadingAddTransport] = useState(false);
    // Валидация для проверки
    const [isValidationLoginAndEmail, setIsValidationLoginAndEmail] = useState(false);

    const { dictionary, idUser, idDevice } = useAppContext();

    const options = [
        { value: 'Двигатель', label: dictionary.engine },
        { value: 'Подвеска', label: dictionary.suspenssion },
        { value: 'Трансмиссия', label: dictionary.transmission },
        { value: 'Разное', label: dictionary.other },
    ]

    const arrayTypesOfWork = ['Двигатель', 'Подвеска', 'Трансмиссия', 'Разное'];

    function submit() {
        if (isValidForm) {
            onSubmit(updateData);
        }
    }

    function addWork(work) {
        const updateWorks = [...works, work]
        setWorks(updateWorks);
    }

    function removeWork(work) {
        const updateWorks = works.filter(element => element.id !== work.id);
        setWorks(updateWorks);
    }

    function addTransport(data) {
        data.VIN = VIN;
        setIsLoadingAddTransport(true);
        transportApi.createTransportByServiceEmployee(data, idDevice)
            .then((data) => {
                setTransport(data.transport);
                setOpenTransportForm(false);
                setIsLoadingAddTransport(false);
                setIsOpenCheckTransportForm(true);
            })
            .catch((err) => {
                openInfoTooltip(dictionary.errorMessageAddTransport);
                console.log(err);
                setIsLoadingAddTransport(false);
            });
    }

    function sendEmailUpdateTransport(data) {
        data.VIN = VIN;
        const recipientEmail = supportEmail;
        const themeEmail = dictionary.emailThemeUpdateTransport;
        const subject =
            `${dictionary.emailSubject1UpdateTransport} 
         ${dictionary.emailSubject2UpdateTransport}:
         ${dictionary.emailSubject3UpdateTransport}: ${data.type.label}
         ${dictionary.emailSubject4UpdateTransport}: ${data.VIN}
         ${dictionary.emailSubject5UpdateTransport}: ${data.brand} 
         ${dictionary.emailSubject6UpdateTransport}: ${data.model}`;

        sendEmail(recipientEmail, themeEmail, subject);
    }

    function updateTransport(data) {
        data.VIN = VIN;
        setIsLoadingAddTransport(true);
        transportApi.updateTransportByServiceEmployee(data, idDevice)
            .then((data) => {
                const updateTransport = data.transport;
                setTransport(updateTransport);
                setIsLoadingAddTransport(false);
                setOpenUpdateTransportForm(false);
                setIsOpenCheckTransportForm(true);
            })
            .catch((error) => {
                setIsLoadingAddTransport(false);
                openInfoTooltip(dictionary.errorMessageUpdateTransport);
                console.log(error.message)
            });
    }

    function openInfoTooltip(text) {
        setIsOpenInfoTooltip(true);
        setTextInfoTooltip(text);
    }

    function closeInfoTool() {
        setIsOpenInfoTooltip(false);
        setTextInfoTooltip('');
    }

    function close() {
        closeForm();
        setRecordName('');
        setMillage('');
        setTypeOfWork('');
        setClientLogin('');
        setVIN('');
        setCost('');
        setWorks([]);
    }

    useEffect(() => {
        if (isValidationLoginAndEmail) {
            setIsLoadingVIN(true);
            getTransportByVIN(VIN, idDevice, setTransport, setOpenUpdateTransportForm, setIsLoadingVIN, setIsOpenCheckTransportForm, setOpenTransportForm)
        }
    }, [isValidationLoginAndEmail])

    // Управлять открытием форм работы с транспортом, закрывать их, если ВИН не валиден
    useEffect(() => {

        if (!regexVIN.test(VIN)) {
            setOpenTransportForm(false);
            setOpenUpdateTransportForm(false);
            setIsOpenCheckTransportForm(false);
            setIsLoadingAddTransport(false);
            setIsLoadingVIN(false);
        }

    }, [VIN])

    // Рассчитать стоимость работ
    useEffect(() => {
        let costWorks = 0;
        if (works.length > 0) {
            works.forEach((work) => {
                costWorks += Number(work.cost);
            })
        }
        setCost(costWorks);
    }, [works])

    async function validationForm(item) {

        if (item) {
            const client = item.client.login;
            const name = item.name;
            const millageTransport = item.millage;
            const VINTransport = item.transport.VIN;
            const type = {
                value: item.type,
                label: item.type,
            }
            const worksTransport = item.works;

            if (client !== clientLogin || name !== recordName || millageTransport !== millage || VINTransport !== VIN || type.value !== typeOfWork.value || worksTransport !== JSON.stringify(works)) {
                let validName = validationRecordName(recordName, setRecordNamevalidationText, dictionary);
                let validMillage = validationMillage(millage, setMillageValidationText, dictionary);
                let validTypeOfWork = validationTypeOfWork(typeOfWork, arrayTypesOfWork, setValidationTypeOfWorkText, dictionary);
                let validWorks = validationWorks(works);
                let validLoginAndVIN = await validationLoginAndVIN(VIN, setValidationVINText, setValidationClientLoginText, dictionary, clientLogin, idDevice)
                setIsValidationLoginAndEmail(validLoginAndVIN);

                let validTransportForm = validationTransportForm(isOpenTransportForm, isOpenUpdateTransportForm);

                if (!validName || !validMillage || !validLoginAndVIN || !validTypeOfWork || !validWorks || !validTransportForm) {
                    setIsValidForm(false);
                } else {
                    setIsValidForm(true);
                }
            } else {
                setIsValidForm(false);
            }

        } else {
            setIsValidForm(false);
        }
    }

    // Валидация
    useEffect(() => {
        validationForm(item);
    }, [recordName, millage, typeOfWork, clientLogin, VIN, works, isOpenTransportForm, isOpenUpdateTransportForm, item])

    // Наполнить инпуты
    useEffect(() => {

        if (item) {
            const client = item.client.login;
            const name = item.name;
            const date = new Date(item.date);
            const millage = item.millage;
            const VIN = item.transport.VIN;
            const type = {
                value: item.type,
                label: item.type,
            }
            const works = JSON.parse(item.works);

            setRecordName(name);
            setMillage(millage);
            setTypeOfWork(type);
            setClientLogin(client);
            setVIN(VIN);
            setWorks(works);
            setDate(date);

        }

    }, [item])

    // Наполнить объект для обновления
    useEffect(() => {
        if (item) {
            let client = item.client.login;
            let name = item.name;
            let millageTransport = item.millage;
            let VINTransport = item.transport.VIN;
            let type = {
                value: item.type,
                label: item.type,
            }
            let worksTransport = item.works;

            let updateObject = {
                idMaintenance: item._id,
                author: idUser,
                idTransport: item.transport._id,
                clientLogin: client,
            };

            if (client !== clientLogin) {
                updateObject.clientLogin = clientLogin;
            }
            if (name !== recordName) {
                updateObject.name = recordName;
            }
            if (millageTransport !== millage) {
                updateObject.millage = millage;
            }
            if (VINTransport !== VIN) {
                updateObject.VIN = VIN;
                updateObject.idTransport = transport._id;
            }
            if (type.value !== typeOfWork.value) {
                updateObject.type = typeOfWork.value;
            }

            if (worksTransport !== JSON.stringify(works)) {
                updateObject.works = works;
                updateObject.cost = cost;
            }
            setUpdateData(updateObject);
        }

    }, [recordName, millage, typeOfWork, clientLogin, VIN, works, isOpenTransportForm, isOpenUpdateTransportForm, item])


    return (
        <InfoTooltip
            isOpen={isOpen}
            closeInfoTool={close}
            text={text}
            customStyle={'form-update-maintenance__infotooltip'}
            customStyleButtonCloseForm={'form-update-maintenance__infotooltip-button-close-form'}
            children={
                <div className="form-add-record form-update-maintenance">
                    <p className="form-add-record__title">{dictionary.formUpdateRecordTitle}</p>
                    <div className="form-add-record__container-input">
                        <InputText
                            inputValue={recordName}
                            setInputChange={setRecordName}
                            placeholder={dictionary.placeholderMaintenaceName}
                            label={dictionary.labelMaintenaceName}
                            validationText={recordNamevalidationText}
                        />
                        <InputSelect
                            options={options}
                            value={typeOfWork}
                            setSelectValue={setTypeOfWork}
                            placeholder={dictionary.placeholderTypeOfWork}
                            validationText={validationTypeOfWorkText}
                        />
                        <InputText
                            inputValue={millage}
                            setInputChange={setMillage}
                            placeholder={dictionary.placeholderMillage}
                            label={dictionary.placeholderMillage}
                            validationText={millageValidationText}
                        />
                        <InputText
                            inputValue={clientLogin}
                            setInputChange={setClientLogin}
                            placeholder={dictionary.placeholderClientLogin}
                            label={dictionary.labelLoginClientName}
                            validationText={validationClientLoginText}
                        />
                        <div className="form-add-record__input-vin-with-loader">
                            <InputText
                                inputValue={VIN}
                                setInputChange={setVIN}
                                placeholder={dictionary.placeholderVIN}
                                label={dictionary.placeholderVIN}
                                validationText={validationVINText}
                            />
                            {isLoadingVIN ?
                                <div className="form-add-record__loader">
                                    <Loader customStyle={'loader__gray__w25h25'} />
                                </div>
                                : <></>}
                        </div>
                        {isOpenTransportForm && regexVIN.test(VIN) ?
                            <FormAddTransport
                                onSubmit={addTransport}
                                isLoading={isLoadingAddTransport}
                            />
                            : <></>}
                        {isOpenCheckTransportForm && regexVIN.test(VIN) ?
                            <FormCheckTransport
                                onSubmit={sendEmailUpdateTransport}
                                transport={transport}
                            />
                            : <></>}
                        {isOpenUpdateTransportForm && regexVIN.test(VIN) ?
                            <FormUpdateTransport
                                onSubmit={updateTransport}
                                transport={transport}
                                isLoading={isLoadingAddTransport}
                            />
                            : <></>}
                    </div>
                    <div className="form-add-record__container-list-works">
                        <FormAddWork onSubmit={addWork} arrayWorks={works} onClickRemoveItem={removeWork} />
                    </div>
                    <ButtonWithBackgroundColor text={dictionary.buttonUpdate} isLoading={false} isDisabled={!isValidForm} onClick={submit} />
                    <p className="form-add-work__error-message">{messageValidForm}</p>
                    <InfoTooltip isOpen={isOpenInfoTooltip} closeInfoTool={closeInfoTool} text={textInfoTooltip} />
                </div>
            }
        />
    )
}

export default FormUpdateMaintenance;