import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../context/AppContext';

import ButtonNavigationBack from "../../components/ButtonNavigationBack/ButtonNavigationBack";
import ButtonWithBorder from "../../components/ButtonWithBorder/ButtonWithBorder";
import EmployeesList from "./components/EmployeesList/EmployeesList";

import serviceApi from "../../utils/api/serviceApi";
import SearchForm from "../../components/SearchForm/SearchForm";
import Loader from "../../components/Loader/Loader";
import InfoTooltip from "../../components/InfoTooltip/InfoTooltip";
import FormUpdateService from "./components/FormUpdateService/FormUpdateService";


function ServicesPage() {

    const [service, setService] = useState(undefined);
    const [serviceEmployees, setServiceEmployees] = useState([]);
    const [searchingStroke, setSearchingStroke] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenInfoTooltip, setIsOpenInfoTooltip] = useState(false);
    const [textInfoTooltip, setTextInfoTooltip] = useState('');
    // Формы

    //
    const [isServiceEmployees, setIsServiceEmployees] = useState(false);
    const [isService, setIsService] = useState(true);
    // Лоадеры
    const [isLoadinAddServiceEmployees, setIsLoadinAddServiceEmployees] = useState(false);

    const navigate = useNavigate();
    const { dictionary, idDevice } = useAppContext();

    function onClickBackPage(e) {
        e.preventDefault();
        navigate('/');
    }


    function closeInfoTool() {
        setIsOpenInfoTooltip(false);
    }

    function search(searchKey) {
        serviceApi.getServiceBySearch(searchKey, idDevice)
            .then((data) => {
                setServiceEmployees(data.serviceEmployees)
                setService(data.service)
                console.log(data)
            })
            .catch((err) => { console.log(err) })
    }

    function openServiceEmployees() {
        setIsServiceEmployees(true);
        setIsService(false);
    }

    function openService() {
        setIsServiceEmployees(false);
        setIsService(true);
    }


    function openInfoTooltip(text) {
        setIsOpenInfoTooltip(true);
        setTextInfoTooltip(text);
    }

    useEffect(() => {

    }, [])

    return (
        <section className="clients">
            <ButtonNavigationBack onClick={onClickBackPage} />
            <h2 className="clients__title">{dictionary.textServices}</h2>
            <div className="clients__search-form">
                <SearchForm
                    onSubmit={search}
                    text={searchingStroke}
                    setText={setSearchingStroke}
                    placeholder={dictionary.placeholderServiceSearch}
                />
            </div>
            {isLoading ?
                <Loader customStyle={'loader__gray__w25h25'} />
                :
                <div className="service__containeiner">
                    <div className="service__container-buttons">
                        <ButtonWithBorder text={'Сервис'} onClick={openService} />
                        {service ?
                            <ButtonWithBorder text={'Сотрудники'} onClick={openServiceEmployees} />
                            : <></>}
                    </div>
                    {isServiceEmployees ?
                        <EmployeesList
                            serviceEmployees={serviceEmployees}
                            setServiceEmployees={setServiceEmployees}
                            service={service}
                        />
                        : <></>}
                    {isService && service ?
                        <FormUpdateService item={service} />
                        : <></>}
                </div>}
            <InfoTooltip isOpen={isOpenInfoTooltip} text={textInfoTooltip} closeInfoTool={closeInfoTool} />
        </section>
    )
}

export default ServicesPage;