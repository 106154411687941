import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../../../context/AppContext';
import imgAccount from '../../../../images/account-svgrepo-com.svg'

function Header({ }) {
    const [isProfileButton, setProfileButton] = useState(true);

    const { dictionary, dataService } = useAppContext();
    let location = useLocation();
    const navigate = useNavigate();

    function onClickAccount(e) {
        e.preventDefault();
        navigate('/account');
    }

    useEffect(() => {
        if (location.pathname === '/') {
            setProfileButton(true);
        } else {
            setProfileButton(false);
        }
    }, [location]);

    return (
        <header className='header'>
            <p className='header-title'>{dataService ? dataService.name : dictionary.textTitleApp}</p>
            {isProfileButton ?
                <button className='header-button' onClick={onClickAccount}>
                    <img className='header-img' src={imgAccount} alt={imgAccount} />
                </button>
                : <></>}
        </header>
    )
}

export default Header;