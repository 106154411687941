import { Routes, Route } from 'react-router-dom';
import { useAppContext } from './context/AppContext';
import Header from './Pages/MainPage/Components/Header/Header';
import Footer from './components/Footer/Footer';
import MainPage from './Pages/MainPage/MainPage';
import NewServicePage from './Pages/NewServicePage/NewServicePage';
import CustomerRecord from './Pages/CustomerRecord/CustomerRecord';
import Clients from './Pages/Clients/Clients';
import Maintenances from './Pages/Maintenances/Maintenances';
import LoginPage from './Pages/LoginPage/LoginPage';
import AccountPage from './Pages/Account/AccountPage';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import LoaderPage from './components/LoaderPage/LoaderPage';
import ServicesPage from './Pages/Services/ServicesPage';

function App() {

  const { isAuthenticated, isLoadingAuthenticated } = useAppContext();

  return (
    <div className="app">
      <main className="app-page">
        <Header />
        {isLoadingAuthenticated ?
          <LoaderPage />
          :
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={isAuthenticated ? <MainPage /> : <LoginPage />} />
            <Route path="/add-service" element={isAuthenticated ? <NewServicePage /> : <NotFoundPage />} />
            <Route path="/services" element={isAuthenticated ? <ServicesPage /> : <NotFoundPage />} />
            <Route path="/customer-record" element={isAuthenticated ? <CustomerRecord /> : <NotFoundPage />} />
            <Route path="/clients" element={isAuthenticated ? <Clients /> : <NotFoundPage />} />
            <Route path="/maintenances" element={isAuthenticated ? <Maintenances /> : <NotFoundPage />} />
            <Route path="/account" element={isAuthenticated ? <AccountPage /> : <NotFoundPage />} />
            <Route path="/*" element={<NotFoundPage />} />
          </Routes>}
        <Footer />
      </main>
    </div>
  );
}

export default App;
