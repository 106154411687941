import { useState } from "react";
import { useAppContext } from '../../../../context/AppContext';
import ButtonWithBackgroundColor from "../../../../components/ButtonWithBackgroundColor/ButtonWithBackgroundColor";
import ListServiceEmployee from "../ListServiceEmployee";
import FormAddServiceEmployees from "../FormAddServiceEmployees/FormAddServiceEmployees";
import FormChoiceEditRemove from "../../../../components/FormChoiceEditRemove/FormChoiceEditRemove";
import FormUpdateServiceEmployee from "../FormUpdateServiceEmployee/FormUpdateServiceEmployee";
import FormRemove from "../../../../components/FormRemove/FormRemove";
import serviceApi from "../../../../utils/api/serviceApi";
import InfoTooltip from "../../../../components/InfoTooltip/InfoTooltip";

function EmployeesList({ serviceEmployees, setServiceEmployees, service }) {

    const [selectedItem, setSelectedItem] = useState({});
    // Формы
    const [isOpenFormAddServiceEmployees, setIsOpenFormAddServiceEmployees] = useState(false);
    const [isOpenFormChoiceEditRemove, setIsOpenFormChoiceEditRemove] = useState(false);
    const [isOpenFormUpdateServiceEmployee, setIsOpenFormUpdateServiceEmployee] = useState(false);
    const [isOpenFormRemoveServiceEmployee, setIsOpenFormRemoveServiceEmployee] = useState(false);
    const [isOpenInfoTooltip, setIsOpenInfoTooltip] = useState(false);
    const [textInfoTooltip, setTextInfoTooltip] = useState('');
    // Лоадеры
    const [isLoadingUpdateServiceEmployees, setIsLoadingUpdateServiceEmployees] = useState(false);
    const [isLoadinAddServiceEmployees, setIsLoadinAddServiceEmployees] = useState(false);
    const [isLoadinRemoveServiceEmployees, setIsLoadinRemoveServiceEmployees] = useState(false);

    const { idDevice } = useAppContext();

    function openFormAddServiceEmployee() {
        setIsOpenFormAddServiceEmployees(true);
    }

    function closeFormAddServiceEmployees() {
        setIsOpenFormAddServiceEmployees(false);
    }

    function clickRemove() {
        setIsOpenFormRemoveServiceEmployee(true);
        closeFormChoiceEditRemove();
    }

    function clickEdit() {
        setIsOpenFormUpdateServiceEmployee(true);
        closeFormChoiceEditRemove();
    }

    function closeFormChoiceEditRemove() {
        setIsOpenFormChoiceEditRemove(false);
    }

    function onClickItem(item) {
        setSelectedItem(item);
        console.log(item);
        setIsOpenFormChoiceEditRemove(true);
    }

    function addServiceEmployees(data) {
        setIsLoadinAddServiceEmployees(true);
        data.service = service._id;
        serviceApi.createServiceEmployee(data, idDevice)
            .then((data) => {
                const newEmployees = data.serviceEmployee;
                const update = { ...serviceEmployees, newEmployees }
                setServiceEmployees(update);
                setIsLoadinAddServiceEmployees(false);
                openInfoTooltip(`Успешно, логин и пароль отправлены на почту ${data.email}`);
            })
            .catch((err) => {
                console.log(err)
                setIsLoadinAddServiceEmployees(false);
                openInfoTooltip(err.message);
            }
            )
    }

    function closeFormUpdateServiceEmployee() {
        setIsOpenFormUpdateServiceEmployee(false);
    }

    function updateServiceEmployee(data) {

        serviceApi.updateServiceEmployee(data, idDevice)
            .then((data) => {
                const updateEmployee = data.serviceEmployee;
                const update = [...serviceEmployees];
                const updateEmployees = update.map((item) => {
                    if (item._id === updateEmployee._id) {
                        return updateEmployee;
                    } else {
                        return item;
                    }
                })
                setServiceEmployees(updateEmployees);
                openInfoTooltip('Успешно');
                closeFormUpdateServiceEmployee();
            })
            .catch((error) => {
                openInfoTooltip(error.message);
                closeFormUpdateServiceEmployee();
            });

    }

    function openInfoTooltip(text) {
        setIsOpenInfoTooltip(true);
        setTextInfoTooltip(text);
    }

    function closeInfoTool() {
        setIsOpenInfoTooltip(false);
    }

    function submitRecoveryPassword() {
        const data = {
            idEmployee: selectedItem._id,
            email: selectedItem.email,
        }
        serviceApi.recoveryPasswordServiceEmployee(data, idDevice)
            .then((data) => {
                closeFormUpdateServiceEmployee();
                openInfoTooltip(`Новый пароль отправлен пользователю на почту ${selectedItem.email}`)
            })
            .catch((error) => {
                closeFormUpdateServiceEmployee();
                openInfoTooltip(error.message);

            });
    }

    function closeFormRemoveServiceEmployee() {
        setIsOpenFormRemoveServiceEmployee(false);
    }

    function removeItem() {
        setIsLoadinRemoveServiceEmployees(true);
        const idEmployee = selectedItem._id;
        serviceApi.deletePasswordServiceEmployee(idEmployee, idDevice)
            .then((data) => {
                setIsLoadinRemoveServiceEmployees(false);
                closeFormRemoveServiceEmployee();
                openInfoTooltip('Успешно');
                const update = [...serviceEmployees];
                const filterData = update.filter(data => data._id !== idEmployee);
                setServiceEmployees(filterData);
            })
            .catch((err) => {
                setIsLoadinRemoveServiceEmployees(false);
                closeFormRemoveServiceEmployee();
                openInfoTooltip(err.message);
            })
    }

    return (

        <div className="service__serviceEmployees">
            <div className="service__serviceEmployees-button-add">
                <ButtonWithBackgroundColor text={'Добавить сотрудника'} onClick={openFormAddServiceEmployee} />
            </div>
            {serviceEmployees.length > 0 ?
                <ListServiceEmployee array={serviceEmployees} onClickItem={onClickItem} />
                : <p>Нет записей</p>}
            <FormAddServiceEmployees
                isOpen={isOpenFormAddServiceEmployees}
                closeForm={closeFormAddServiceEmployees}
                isLoading={isLoadinAddServiceEmployees}
                onSubmit={addServiceEmployees}
            />
            <FormChoiceEditRemove
                isOpen={isOpenFormChoiceEditRemove}
                closeForm={closeFormChoiceEditRemove}
                text={selectedItem.name}
                onEdit={clickEdit}
                onRemove={clickRemove}
            />
            {isOpenFormUpdateServiceEmployee ?
                <FormUpdateServiceEmployee
                    item={selectedItem}
                    isOpen={isOpenFormUpdateServiceEmployee}
                    closeForm={closeFormUpdateServiceEmployee}
                    onSubmit={updateServiceEmployee}
                    submitRecoveryPassword={submitRecoveryPassword}
                    isLoading={isLoadingUpdateServiceEmployees}
                />
                : <></>}
            <FormRemove
                isOpen={isOpenFormRemoveServiceEmployee}
                closeForm={closeFormRemoveServiceEmployee}
                onRemove={removeItem}
                isLoading={isLoadinRemoveServiceEmployees}
            />
            <InfoTooltip isOpen={isOpenInfoTooltip} text={textInfoTooltip} closeInfoTool={closeInfoTool} />

        </div>)
}

export default EmployeesList;